<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('TariffCallCreate')"
    @edit="(id) => onEdit('TariffCallEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TariffCalls',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TariffCalls',
      tableCaption: 'Тарифы на звонки',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Тарифы на звонки',
        },
      ],
      tableHeaders: [
        { text: 'Имя клиента', alias: 'customerName', order: 'customerName' },
        { text: 'Тариф', alias: 'tariff', order: 'tariff' },
        { text: '', alias: 'actions', order: '' },
      ],
    };
  },
};
</script>
